import React, { Fragment, useState, useEffect } from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
const Info = () => {
  const [solarInfo, setSolarInfo] = useState({
    generalInfo: {
      key: "",
      name: "",
      city: "",
      street: "",
      utcOffset: "",
      postalCode: "",
      nominalPower: 0,
      siteArea: 0,
      nbPanels: 0,
      nbInverters: 0,
      picture: "",
    },
    dailyInfo: {
      hasMeters: 0,
      solarRatio: 0,
      consoBatiment: 0,
      consoResau: 0,
      injectionReseau: 0,
      production: 0,
      actualPower: 0,
      powerRatio: 0,
      avgPower: 0,
      totalValue: 0,
      maxValue: 0,
      data: [],
    },
    monthlyInfo: {
      totalValue: 0,
      maxValue: 0,
      data: [],
    },
    annualInfo: {
      totalValue: 0,
      maxValue: 0,
      data: [],
    },
  });
  const [dailyData, setDailyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [monthlyLabel, setMonthlyLabel] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [yearlyLabel, setYearlyLabel] = useState([]);
  const [fade, setFade] = useState(false);

  const data = {
    labels: [
      "2h00",
      "4h00",
      "6h00",
      "8h00",
      "10h00",
      "12h00",
      "14h00",
      "16h00",
      "18h00",
      "20h00",
      "22h00",
    ],
    datasets: [
      {
        label: "Puissance",
        data: dailyData,
        fill: true,
        backgroundColor: "rgba(76, 217, 100, .2)",
        borderColor: "rgba(76, 217, 100, 1)",
        cubicInterpolationMode: "monotone",
      },
    ],
  };
  const dataMonth = {
    labels: monthlyLabel,
    datasets: [
      {
        label: "Puissance",
        data: monthlyData,
        fill: true,
        backgroundColor: "rgba(76, 217, 100, .2)",
        borderColor: "rgba(76, 217, 100, 1)",
        cubicInterpolationMode: "monotone",
      },
    ],
  };
  const dataYear = {
    labels: yearlyLabel,
    datasets: [
      {
        label: "Puissance",
        data: yearlyData,
        fill: true,
        backgroundColor: "rgba(76, 217, 100, .2)",
        borderColor: "rgba(76, 217, 100, 1)",
        cubicInterpolationMode: "monotone",
      },
    ],
  };
  let delayed;
  const options = {
    animation: {
      onComplete: () => {
        delayed = true;
      },
      delay: (context) => {
        let delay = 0;
        if (context.type === "data" && context.mode === "default" && !delayed) {
          delay = context.dataIndex * 300 + context.datasetIndex * 100;
        }
        return delay;
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "Chart.js Line Chart - Cubic interpolation mode",
      },
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Puissance kWh",
        },
        suggestedMin: 0,
        suggestedMax: 300,
      },
    },
  };
  const getSolarInfo = async () => {
    try {
      const response = await fetch("https://grangeweb.ch/solarProxy/solar");
      const jsonData = await response.json();
      setSolarInfo(jsonData);

      let dailyArry = [];
      jsonData.dailyInfo.data.forEach((element) => {
        dailyArry.push(element.value);
      });
      setDailyData(dailyArry);

      let monthlyArray = [];
      let monthlyArrayLabel = [];
      jsonData.monthlyInfo.data.forEach((element) => {
        monthlyArray.push(element.value);
        monthlyArrayLabel.push(element.dateFormat);
      });
      setMonthlyData(monthlyArray);
      setMonthlyLabel(monthlyArrayLabel);

      let yearlyArray = [];
      let yearlyArrayLabel = [];
      jsonData.annualInfo.data.forEach((element) => {
        yearlyArray.push(element.value);
        yearlyArrayLabel.push(element.dateFormat);
      });
      setYearlyData(yearlyArray);
      setYearlyLabel(yearlyArrayLabel);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    setFade(true);
    getSolarInfo();
  }, []);
  return (
    <Fragment>
      <div
        className={
          "bg-white  dark:border-slate-600 dark:bg-slate-700 dark:text-white flex flex-col rounded-xl p-6 mt-4 w-full md:w-[1200px] transition-opacity duration-700 " +
          (fade ? "opacity-100" : "opacity-0")
        }
      >
        <div className="flex flex-row">
          <div className="flex flex-col content-center">
            <div className="font-semibold self-start mb-2 text-lg">
              Panneaux solaires de Grangneuve
            </div>
            <div className="flex flex-col w-full md:flex-row ">
              <div className="flex flex-col">
                <div className="flex flex-row bg-gray-100 dark:bg-slate-600 text-sm font-medium w-full md:w-80 rounded-md p-3 mb-2">
                  Location{" "}
                  <div className="text-gray-400 ml-2">
                    Grangeneuve Ferme-Ecole
                  </div>
                </div>
                <div className="flex flex-row bg-gray-100 dark:bg-slate-600 text-sm font-medium w-full md:w-80 rounded-md p-3 ">
                  Nombre de panneaux{" "}
                  <div className="text-gray-400 ml-2">
                    {solarInfo.generalInfo.nbPanels.toLocaleString("de-ch")}
                  </div>
                </div>
              </div>
              <div className="flex flex-col mt-2 md:mt-0 md:ml-2">
                <div className="flex flex-row bg-gray-100 dark:bg-slate-600 text-sm font-medium w-full md:w-80 rounded-md p-3 mb-2">
                  Surface{" "}
                  <div className="text-gray-400 ml-2">
                    {solarInfo.generalInfo.siteArea.toLocaleString("de-ch")} m²
                  </div>
                </div>
                <div className="flex flex-row bg-gray-100 dark:bg-slate-600 text-sm font-medium w-full md:w-80 rounded-md p-3 ">
                  Puissance nominale{" "}
                  <div className="text-gray-400 ml-2">
                    {solarInfo.generalInfo.nominalPower.toLocaleString("de-ch")}{" "}
                    kWp
                  </div>
                </div>
              </div>
              <div className="bg-gray-100 dark:bg-slate-600 rounded-md p-4 px-6 md:ml-2 mt-2 md:mt-0 w-full md:w-48 h-24">
                <div className="flex flex-row items-end">
                  <span class="flex h-3 w-3 self-center mr-2">
                    <span class="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-green-400 opacity-75"></span>
                    <span class="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
                  </span>
                  <span className="text-2xl font-semibold mr-2">
                    {solarInfo.dailyInfo.actualPower.toLocaleString("de-ch")}
                  </span>{" "}
                  <span className="text-gray-400 font-medium">kW</span>
                </div>
                <div className="mt-1">
                  <div className="text-sm text-left">Puissance actuelle</div>
                </div>
              </div>
              <div className="bg-gray-100 dark:bg-slate-600 rounded-md p-4 px-6 md:ml-2 mt-2 md:mt-0 w-full md:w-48  h-24">
                <div className="flex flex-row items-end">
                  <div className="text-2xl font-semibold mr-2">
                    {solarInfo.dailyInfo.totalValue.toLocaleString("de-ch")}
                  </div>{" "}
                  <div className="text-gray-400 font-medium">kWh</div>
                </div>
                <div className="mt-1">
                  <div className="text-sm text-left">Energie actuelle </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          "bg-white dark:border-slate-600 dark:bg-slate-700 dark:text-white flex flex-col rounded-xl p-6 mt-4 w-full md:w-[1200px] transition-opacity duration-700 delay-300 " +
          (fade ? "opacity-100" : "opacity-0")
        }
      >
        <div className="font-semibold text-lg text-left ml-6 mb-4">
          Production d'énergie journalière
        </div>
        <div className="flex flex-col md:flex-row justify-start">
          <div className="bg-gray-100 dark:bg-slate-600 rounded-md md:ml-6 mb-6 md:mr-4 h-20 w-full md:w-60 p-3">
            <div className="flex flex-row items-end">
              <div className="text-2xl font-semibold mr-2">
                {solarInfo.dailyInfo.maxValue.toLocaleString("de-ch")}
              </div>{" "}
              <div className="text-gray-400 font-medium">kWh</div>
            </div>
            <div className="mt-1">
              <div className="text-sm text-left">
                Puissance Maximal atteinte
              </div>
            </div>
          </div>
          <div className="bg-gray-100 dark:bg-slate-600 rounded-md md:ml-6 mb-6 md:mr-4 h-20 w-full md:w-60 p-3">
            <div className="flex flex-row items-end">
              <div className="text-2xl font-semibold mr-2">
                {(solarInfo.dailyInfo.powerRatio * 100).toFixed()}
              </div>{" "}
              <div className="text-gray-400 font-medium">%</div>
            </div>
            <div className="mt-1">
              <div className="text-sm text-left">Puissance </div>
            </div>
          </div>
        </div>
        <Line data={data} options={options} />
      </div>
      <div
        className={
          "bg-white dark:border-slate-600 dark:text-white dark:bg-slate-700 flex flex-col rounded-xl p-6 mt-4 w-full md:w-[1200px] transition-opacity duration-700 delay-700 " +
          (fade ? "opacity-100" : "opacity-0")
        }
      >
        <div className="font-semibold text-lg text-left ml-6 mb-4">
          Production d'énergie mensuelle
        </div>
        <div className="flex flex-col md:flex-row justify-start">
          <div className="bg-gray-100 dark:bg-slate-600 rounded-md md:ml-6 mb-6 md:mr-4 h-20 w-full md:w-60 p-3">
            <div className="flex flex-row items-end">
              <div className="text-2xl font-semibold mr-2">
                {solarInfo.monthlyInfo.maxValue.toLocaleString("de-ch")}
              </div>{" "}
              <div className="text-gray-400 font-medium">kWh</div>
            </div>
            <div className="mt-1">
              <div className="text-sm text-left">
                Puissance Maximal atteinte
              </div>
            </div>
          </div>
          <div className="bg-gray-100 dark:bg-slate-600 rounded-md md:ml-6 mb-6 md:mr-4 h-20 w-full md:w-60 p-3">
            <div className="flex flex-row items-end">
              <div className="text-2xl font-semibold mr-2">
                {solarInfo.monthlyInfo.totalValue.toLocaleString("de-ch")}
              </div>{" "}
              <div className="text-gray-400 font-medium">kWh</div>
            </div>
            <div className="mt-1">
              <div className="text-sm text-left">Energie Totale </div>
            </div>
          </div>
        </div>
        <Line data={dataMonth} options={options} />
      </div>
      <div
        className={
          "bg-white dark:text-white dark:border-slate-600 dark:bg-slate-700 flex flex-col rounded-xl p-6 mt-4 w-full md:w-[1200px] transition-opacity duration-700 delay-1000 " +
          (fade ? "opacity-100" : "opacity-0")
        }
      >
        <div className="font-semibold text-lg text-left ml-6 mb-4">
          Production d'énergie annuelle
        </div>
        <div className="flex flex-col md:flex-row justify-start">
          <div className="bg-gray-100 dark:bg-slate-600 rounded-md md:ml-6 mb-6 md:mr-4 h-20 w-full md:w-60 p-3">
            <div className="flex flex-row items-end">
              <div className="text-2xl font-semibold mr-2">
                {solarInfo.annualInfo.maxValue.toLocaleString("de-ch")}
              </div>{" "}
              <div className="text-gray-400 font-medium">kWh</div>
            </div>
            <div className="mt-1">
              <div className="text-sm text-left">
                Puissance Maximal atteinte
              </div>
            </div>
          </div>
          <div className="bg-gray-100 dark:bg-slate-600 rounded-md md:ml-6 mb-6 md:mr-4 h-20 w-full md:w-60 p-3">
            <div className="flex flex-row items-end">
              <div className="text-2xl font-semibold mr-2">
                {solarInfo.annualInfo.totalValue.toLocaleString("de-ch")}
              </div>{" "}
              <div className="text-gray-400 font-medium">kWh</div>
            </div>
            <div className="mt-1">
              <div className="text-sm text-left">Energie Totale </div>
            </div>
          </div>
        </div>
        <Line data={dataYear} options={options} />
      </div>
    </Fragment>
  );
};
export default Info;
