import "./App.css";
import Info from "./components/Info";

function App() {
  return (
    <div className="App">
      <div className="flex flex-row justify-center bg-black dark:bg-gray-800 h-16 px-4 w-full shadow">
        <div className="flex justify-start items-center w-[1200px]">
          <img className="h-10 mr-4 md:mr-10" src="/header-logo.svg" />
          <span className="text-lg font-semibold text-white">
            Grangeneuve Solaire
          </span>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center px-4">
        <Info />
      </div>
      <div className="bg-black dark:bg-slate-900 h-80 mt-10 flex flex-col items-center">
        <div className="flex flex-row w-full md:w-[1200px] h-full justify-center items-center">
          <div className="flex flex-row">
            <img className="h-24 md:h-36" src="footer-logo.svg" />
            <div className="border-l border-white h-36 mx-4 md:mx-10"></div>
          </div>
          <div className="flex flex-col h-36 w-52 text-sm md:text-base font-medium text-white text-left">
            <a href="https://www.grangeneuve.ch">Grangeneuve</a>
            <a href="mailto:iaginfo@fr.ch">Contact</a>
            <a href="https://linktr.ee/Grangeneuve">Réseau sociaux</a>
            <p className="text-white text-xs md:text-sm mt-3">
              Fait avec &#128154; par le service informatique de Grangeneuve.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
